import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
export type DisrupterObjectFragment = {
  __typename?: 'DisrupterObject';
  mainTitle?: string | null;
  subTitle?: string | null;
  text: string;
  fontColor?: string | null;
  backgroundColor?: string | null;
  iconType?: string | null;
};

export const DisrupterObjectFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'disrupterObject' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'DisrupterObject' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'mainTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'iconType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
