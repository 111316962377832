import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const CustomSelectInputWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    userSelect: 'none',
    display: ['block', 'flex'],
    alignItems: 'center',
    '.label': {
      m: 0,
      mr: 2,
      color: 'textGray',
      fontWeight: 'bold',
      fontSize: 0,
      whiteSpace: 'nowrap',
    },
    '.absolute-dropdown': {
      width: '100%',
      bg: 'backgroundGray',
      borderLeft: '1px solid #ccc',
      borderBottom: '1px solid #ccc',
      boxShadow: ['0 1px 7px rgb(0 0 0 / 20%)', 'none'],
    },
  })
);

export const CustomInput = styled.div(
  ({ theme }) => theme.inputs.select,
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: ['100%', '170px'],
    width: 'fit-content',
    pr: 2,
    borderRadius: '0px',
    borderTop: [null, 'none'],
    borderRight: 'none',
    height: '30px',
    fontWeight: 'bold',
    background: '#e6e6e6',
    boxShadow: [null, 'inset 1px 1px 4px rgba(0,0,0,0.4)'],
    color: 'textGray',
    '&:hover': {
      background: '#e6e6e6',
      cursor: 'pointer',
    },
    '.value': {
      pr: 2,
    },
  })
);

export const ChevronArrow = styled(Icon).attrs({
  name: 'chevron-down',
  className: 'chevron',
})(
  sx2CssThemeFn({
    width: '13px',
    height: '7px',
    pointerEvents: 'none',
  })
);

export const CustomOption = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    p: '7px 10px 6px',
    whiteSpace: 'nowrap',
    fontSize: 0,
    fontWeight: 'bold',
    color: 'textGray',
    cursor: 'pointer',
    '&:not(:last-child)': {
      borderBottom: '1px solid #ccc',
    },
    '&:hover': {
      bg: '#fff',
      color: 'primary',
    },
  })
);
