import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { PriceFragmentDoc } from 'graphql/fragments/Price.generated';
import { CoordinatesFragmentDoc } from 'graphql/fragments/Coordinates.generated';
export type SrlMapProductPinFragment = {
  __typename?: 'SrlMapProductPin';
  id: string;
  name: string;
  bestseller: boolean;
  zoom: number;
  price: { __typename?: 'Price'; amount: string; currency: string };
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
};

export const SrlMapProductPinFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapProductPin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlMapProductPin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bestseller' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoom' } },
        ],
      },
    },
    ...PriceFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
