import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useRef } from 'react';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { PageType, TravelType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import PrimaryButton from 'components/core/buttons/PrimaryButton';
import PrimaryButtonLink from 'components/core/buttons/PrimaryButtonLink';
import { useDefaultSearchStateByPageType } from 'components/domain/srl/useDefaultSearchState';
import useUpdateSearchState from 'components/domain/srl/useUpdateSearchState';
import {
  EmptyResultsModalWrap,
  EmptyResultsTooltip,
  ModalCloseButton,
  SearchButton,
} from './SRLEmptyResults.styled';
import { IAlternativeProps } from './SRLEmptyResults.types';

interface ISRLAlternativeSearchModalProps extends IAlternativeProps {
  customTransition?(): void;
  resetValues?: boolean;
  searchButtonTranslation?: string;
}

export default function SRLAlternativeSearchModal({
  alternativeUrl,
  alternativeTravelType,
  customTransition,
  resetValues,
  searchButtonTranslation = 'results:noOrlModal.buttonContent',
}: ISRLAlternativeSearchModalProps): React.ReactElement {
  const [t] = useTranslation(['common', 'results']);
  const { query } = useRouter();
  const { id } = useObjectId();
  const pageType = usePageType<PageType>();

  const { handleAlternativeUrl } = useUpdateSearchState();
  const { onReset } = useDefaultSearchStateByPageType(pageType)(id || null);
  const hasSearchQueryParam = useRef<boolean>(
    Boolean('s' in query && query['s'])
  );

  const [isModalShowed, , closeModal] = useToggleState(
    !(!hasSearchQueryParam.current && alternativeUrl)
  );

  const description = useMemo(() => {
    if (Boolean(alternativeUrl || customTransition)) {
      if (alternativeTravelType === TravelType.Hotel) {
        return t('results:noOffers.hotels.text');
      } else if (alternativeTravelType === TravelType.Package) {
        return t('results:noOffers.packages.text');
      }
    }

    return t('results:noOffers.description');
  }, [alternativeUrl, alternativeTravelType, customTransition]);

  useEffect(
    function redirectToAltTravelType() {
      if (!hasSearchQueryParam.current) {
        handleAlternativeUrl(alternativeTravelType, alternativeUrl);
      }
    },
    [hasSearchQueryParam.current, alternativeUrl, alternativeTravelType]
  );

  if (!isModalShowed) return null;

  return (
    <EmptyResultsModalWrap>
      <ModalCloseButton onClick={closeModal}>{`Close window`}</ModalCloseButton>
      <EmptyResultsTooltip className={'empty-results-tooltip'}>
        <h3 className="description">{description}</h3>
        {alternativeUrl ? (
          <>
            <SearchButton onClick={closeModal}>
              <span>{t('common:no')}</span>
            </SearchButton>
            <RouterLink
              {...getTargetPageData(alternativeUrl)}
              scroll={false}
              passHref
            >
              <PrimaryButtonLink>
                <span>{t('common:yes')}</span>
              </PrimaryButtonLink>
            </RouterLink>
          </>
        ) : (
          <>
            <SearchButton
              onClick={() => {
                resetValues && onReset();
                closeModal();
              }}
            >
              {t(searchButtonTranslation)}
            </SearchButton>
            {customTransition ? (
              <PrimaryButton onClick={customTransition}>
                <span>{t('common:yes')}</span>
              </PrimaryButton>
            ) : null}
          </>
        )}
      </EmptyResultsTooltip>
    </EmptyResultsModalWrap>
  );
}
