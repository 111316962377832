import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import {
  ImageMediaFragmentDoc,
  VideoMediaFragmentDoc,
  ExternalMediaFragmentDoc,
  PdfMediaFragmentDoc,
  MusicMediaFragmentDoc,
} from './MediaComponent.generated';
export type ThumbnailImageFragment = {
  __typename?: 'ImageMediaItem';
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryImageFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  image: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryVideoFragment = {
  __typename?: 'VideoMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryExternalResourceFragment = {
  __typename?: 'ExternalMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryPdfFragment = {
  __typename?: 'PdfMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryMusicFragment = {
  __typename?: 'MusicMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryItemExternalMediaItemFragment = {
  __typename?: 'ExternalMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryItemImageMediaItemFragment = {
  __typename?: 'ImageMediaItem';
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  image: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryItemMusicMediaItemFragment = {
  __typename?: 'MusicMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryItemPdfMediaItemFragment = {
  __typename?: 'PdfMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryItemVideoMediaItemFragment = {
  __typename?: 'VideoMediaItem';
  resource: string;
  title: string;
  thumbnail: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  preview: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export type MediaGalleryItemFragment =
  | MediaGalleryItemExternalMediaItemFragment
  | MediaGalleryItemImageMediaItemFragment
  | MediaGalleryItemMusicMediaItemFragment
  | MediaGalleryItemPdfMediaItemFragment
  | MediaGalleryItemVideoMediaItemFragment;

export const ThumbnailImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'thumbnailImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ImageMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnail' },
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const MediaGalleryImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaGalleryImage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ImageMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'thumbnailImage' },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ImageMedia' },
          },
        ],
      },
    },
    ...ThumbnailImageFragmentDoc.definitions,
    ...ImageMediaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const MediaGalleryExternalResourceFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaGalleryExternalResource' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExternalMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnail' },
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'ExternalMedia' },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...ExternalMediaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const MediaGalleryVideoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaGalleryVideo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VideoMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnail' },
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'VideoMedia' },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...VideoMediaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const MediaGalleryPdfFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaGalleryPdf' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PdfMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnail' },
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PdfMedia' } },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...PdfMediaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const MediaGalleryMusicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaGalleryMusic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MusicMediaItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'thumbnail' },
            name: { kind: 'Name', value: 'preview' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '105' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '80' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'MusicMedia' },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...MusicMediaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const MediaGalleryItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaGalleryItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Media' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ImageMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaGalleryImage' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExternalMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaGalleryExternalResource' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VideoMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaGalleryVideo' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PdfMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaGalleryPdf' },
                },
              ],
            },
          },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MusicMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'MediaGalleryMusic' },
                },
              ],
            },
          },
        ],
      },
    },
    ...MediaGalleryImageFragmentDoc.definitions,
    ...MediaGalleryExternalResourceFragmentDoc.definitions,
    ...MediaGalleryVideoFragmentDoc.definitions,
    ...MediaGalleryPdfFragmentDoc.definitions,
    ...MediaGalleryMusicFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
