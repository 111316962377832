import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';

export const EmptyResultsModalWrap = styled.div.attrs({
  className: 'empty-results-overlay',
})(({ theme: { colors } }) =>
  sx2CssThemeFn({
    width: ['100%', '375px'],
    position: ['relative', 'absolute'],
    top: [null, '85px'],
    left: [null, '8px'],
    backgroundColor: colors.white,
    zIndex: 20,
  })
);

export const EmptyResultsWrap = styled.div.attrs({
  testId: `noOffers`,
})(
  sx2CssThemeFn({
    mx: [0, 3],
    p: '15px',
    border: [null, '1px solid #bbb'],
    borderRadius: 'default',
    alignSelf: 'self-start',
    bg: 'white',
    '.description': {
      fontSize: 1,
      mt: [3, 0],
      mb: 2,
      mr: 'auto',
    },
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
    '.primary-button-link, .primary-button': {
      textTransform: 'uppercase',
      width: '150px',
      ml: 2,
      span: {
        ml: 'auto',
      },
    },
    '.search-button': {
      width: ['250px', 'auto'],
      'span, .icon': {
        ml: ['auto', 0],
      },
    },
  })
);

export const EmptyResultsTooltip = styled(EmptyResultsWrap)(
  sx2CssThemeFn({
    p: '10px',
    mx: 0,
    boxShadow: '0 1px 7px rgb(0 0 0 / 50%)',
    borderColor: '#999',
    '.description': {
      mr: 4,
      mb: 3,
      fontWeight: 'normal',
      fontFamily: 'Arial',
      fontSize: 0,
    },
    '.search-button': {
      width: '150px',
      backgroundColor: 'textGray',
      'span, .icon': {
        ml: 'auto',
      },
    },
  })
);

export const SearchButton = styled(Button).attrs({
  icon: {
    name: 'chevron-long-right',
  },
  className: 'search-button',
})(
  sx2CssThemeFn({
    variant: 'buttons.allOffers',
  })
);

export const ModalCloseButton = styled(Button).attrs({
  variant: 'iconBtn',
  icon: { name: 'close' },
  'data-id': `close`,
})(
  sx2CssThemeFn({
    position: 'absolute',
    top: [3, 2],
    right: [3, 2],
    backgroundColor: 'inherit',
    '.icon': {
      variant: 'icons.sm',
    },
  })
);

export const EmptyModalWrapper = styled.div(({ theme: { media } }) =>
  sx2CssThemeFn({
    [media.mobile]: {
      '.empty-results-tooltip': {
        boxShadow: 'none',
        borderRadius: '0px',
        border: '1px solid',
        borderColor: 'borderGray',
      },
      '.description': {
        fontWeight: 700,
      },
    },
  })
);
