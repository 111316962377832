import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/libs.sx';
import RatingSymbols from 'components/domain/filters/RatingSymbols';
import {
  TSearchFormInput,
  useCurrentSearchContext,
} from './CurrentSearchContext';

const filtersOrder: TSearchFormInput[] = [
  'travelDestination',
  'departureAirports',
  'travelDates',
  'travelRooms',
  'maxPrice',
  'hpRating',
  'taRating',
  'roomTypes',
  'boardTypes',
  'flightOptions',
  'productFeatures',
];

const SrlMobileCurrentSearchWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    px: 2,
    py: 3,
    bg: 'backgroundGray',
    mb: [3, 0],
    borderBottom: '1px solid',
    borderColor: 'borderGray',
  })
);
const Tags = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
  })
);
const Tag = styled.div<{ minWidth?: number }>(
  ({ minWidth }) =>
    minWidth
      ? {
          minWidth: `${minWidth}px`,
        }
      : null,
  sx2CssThemeFn({
    position: 'relative',
    border: '1px solid',
    borderColor: 'borderGray',
    borderRadius: 'default',
    bg: 'white',
    px: 2,
    py: 1,
    fontSize: '12px',
    fontWeight: 'bold',
    span: {
      display: 'flex',
      alignItems: 'center',
    },
  })
);

const RatingSymbolsWrapper = styled(RatingSymbols)(
  sx2CssThemeFn({
    '&:after': {
      top: '-14px',
    },
  })
);

interface ISRLMobileCurrentSearchProps {
  excludedFields?: Array<TSearchFormInput>;
  className?: string;
}

export default function SRLMobileCurrentSearch({
  excludedFields = [],
  className,
}: ISRLMobileCurrentSearchProps): React.ReactElement {
  const currentSearchValues = useCurrentSearchContext();

  return (
    <SrlMobileCurrentSearchWrapper className={className}>
      <Tags>
        {filtersOrder.map(filter => {
          const value = currentSearchValues[filter];
          if (!value || excludedFields.includes(filter)) return null;
          if (filter === 'taRating' || filter === 'hpRating') {
            const [prefix, rating] = value.split(',');
            return (
              <Tag key={filter} minWidth={+rating * 15 + 40}>
                <span>{prefix}</span>
                <RatingSymbolsWrapper
                  filterName={filter}
                  rating={+rating}
                  shift={15}
                  leftMargin={27}
                />
              </Tag>
            );
          }
          return (
            <Tag key={filter}>
              <span dangerouslySetInnerHTML={{ __html: value }} />
            </Tag>
          );
        })}
      </Tags>
    </SrlMobileCurrentSearchWrapper>
  );
}
