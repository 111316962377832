import { useTranslation } from 'next-i18next';
import React from 'react';
import { PageType } from '@hotelplan/graphql.types';
import { useObjectId } from '@hotelplan/libs.context.object-id';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { useDefaultSearchStateByPageType } from 'components/domain/srl/useDefaultSearchState';
import { EmptyResultsWrap, SearchButton } from './SRLEmptyResults.styled';

export default function SRLNoResults(): React.ReactElement {
  const { id } = useObjectId();
  const [t] = useTranslation('results');
  const pageType = usePageType<PageType>();

  const { onReset } = useDefaultSearchStateByPageType(pageType)(id || null);

  return (
    <EmptyResultsWrap>
      <h3 className="description">{t('results:noOffers.description')}</h3>
      <SearchButton onClick={onReset}>
        <span>{t('results:noOffers.searchButton')}</span>
      </SearchButton>
    </EmptyResultsWrap>
  );
}
