import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { GroupOfProductFeatureItemsFragmentDoc } from 'graphql/productFeature/GroupOfProductFeatureItems.generated';
import { GeoFragmentDoc } from 'graphql/geo/Geo.generated';
import { InternalLinkFragmentFragmentDoc } from 'graphql/link/InternalLink.generated';
import { PriceFragmentDoc } from 'graphql/fragments/Price.generated';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
export type Bd4recommendationGroupFragment = {
  __typename?: 'BD4TravelRecommendationGroup';
  name?: string | null;
  items: Array<{
    __typename?: 'BD4TravelRecommendation';
    offerId: string;
    name: string;
    travelType?: string | null;
    duration: number;
    mealType: string;
    hpRating?: number | null;
    taRating?: number | null;
    taReviews?: number | null;
    productClass: Types.ProductClass;
    type: Types.ProductType;
    giata: string;
    transferIncluded?: boolean | null;
    featureSummary: Array<{
      __typename?: 'ProductFeatureGroup';
      id: string;
      name: string;
      features: Array<{
        __typename?: 'ProductFeature';
        id: string;
        name: string;
        enabled?: boolean | null;
      }>;
    }>;
    geoBreadcrumbs: Array<{
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    }>;
    link: {
      __typename?: 'InternalLink';
      caption?: string | null;
      uri: string;
      targetPageType: Types.PageType;
    };
    price: { __typename?: 'Price'; amount: string; currency: string };
    totalPrice: { __typename?: 'Price'; amount: string; currency: string };
    transferPrice?: {
      __typename?: 'Price';
      amount: string;
      currency: string;
    } | null;
    image: {
      __typename?: 'Image';
      alt: string;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    };
  }>;
  tracking?: {
    __typename?: 'BD4TravelRecommendationTracking';
    status: Types.Bd4TravelRecommendationStatus;
    productCount?: number | null;
    productIds?: Array<string> | null;
    ptoken?: string | null;
    info?: {
      __typename?: 'BD4TravelRecommendationInfo';
      modelId: string;
      placementId: string;
      strategy: string;
    } | null;
  } | null;
};

export type Bd4TrackingFragment = {
  __typename?: 'BD4TravelRecommendationTracking';
  status: Types.Bd4TravelRecommendationStatus;
  productCount?: number | null;
  productIds?: Array<string> | null;
  ptoken?: string | null;
  info?: {
    __typename?: 'BD4TravelRecommendationInfo';
    modelId: string;
    placementId: string;
    strategy: string;
  } | null;
};

export type Bd4TrackingInfoFragment = {
  __typename?: 'BD4TravelRecommendationInfo';
  modelId: string;
  placementId: string;
  strategy: string;
};

export type Bd4recommendationFragment = {
  __typename?: 'BD4TravelRecommendation';
  offerId: string;
  name: string;
  travelType?: string | null;
  duration: number;
  mealType: string;
  hpRating?: number | null;
  taRating?: number | null;
  taReviews?: number | null;
  productClass: Types.ProductClass;
  type: Types.ProductType;
  giata: string;
  transferIncluded?: boolean | null;
  featureSummary: Array<{
    __typename?: 'ProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'ProductFeature';
      id: string;
      name: string;
      enabled?: boolean | null;
    }>;
  }>;
  geoBreadcrumbs: Array<{
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  }>;
  link: {
    __typename?: 'InternalLink';
    caption?: string | null;
    uri: string;
    targetPageType: Types.PageType;
  };
  price: { __typename?: 'Price'; amount: string; currency: string };
  totalPrice: { __typename?: 'Price'; amount: string; currency: string };
  transferPrice?: {
    __typename?: 'Price';
    amount: string;
    currency: string;
  } | null;
  image: {
    __typename?: 'Image';
    alt: string;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
};

export const Bd4recommendationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bd4recommendation' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BD4TravelRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'offerId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mealType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hpRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taRating' } },
          { kind: 'Field', name: { kind: 'Name', value: 'taReviews' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productClass' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'giata' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureSummary' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'GroupOfProductFeatureItems' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoBreadcrumbs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'link' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'internalLinkFragment' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '728' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '476' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '364' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '238' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '640' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '430' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1280' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '860' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
        ],
      },
    },
    ...GroupOfProductFeatureItemsFragmentDoc.definitions,
    ...GeoFragmentDoc.definitions,
    ...InternalLinkFragmentFragmentDoc.definitions,
    ...PriceFragmentDoc.definitions,
    ...ResizedImageWithMetaFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const Bd4TrackingInfoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bd4TrackingInfo' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BD4TravelRecommendationInfo' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'modelId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'placementId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'strategy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const Bd4TrackingFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bd4Tracking' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BD4TravelRecommendationTracking' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productIds' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ptoken' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'bd4TrackingInfo' },
                },
              ],
            },
          },
        ],
      },
    },
    ...Bd4TrackingInfoFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const Bd4recommendationGroupFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'bd4recommendationGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'BD4TravelRecommendationGroup' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'items' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'bd4recommendation' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tracking' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'bd4Tracking' },
                },
              ],
            },
          },
        ],
      },
    },
    ...Bd4recommendationFragmentDoc.definitions,
    ...Bd4TrackingFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
