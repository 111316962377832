import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { CoordinatesFragmentDoc } from 'graphql/fragments/Coordinates.generated';
export type SrlMapAirportPinFragment = {
  __typename?: 'Airport';
  iata?: string | null;
  name?: string | null;
  coordinates?: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  } | null;
};

export const SrlMapAirportPinFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapAirportPin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Airport' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'iata' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    ...CoordinatesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
