import type * as Types from 'graphql/types';

import type { DocumentNode } from 'graphql';
import { GeoFragmentDoc } from 'graphql/geo/Geo.generated';
import { PriceFragmentDoc } from 'graphql/fragments/Price.generated';
import { CoordinatesFragmentDoc } from 'graphql/fragments/Coordinates.generated';
export type SrlMapGeoPinFragment = {
  __typename?: 'SrlMapGeoPin';
  zoom: number;
  geo: {
    __typename?: 'GeoObject';
    id: string;
    name: string;
    type: Types.GeoType;
  };
  price: { __typename?: 'Price'; amount: string; currency: string };
  coordinates: {
    __typename?: 'GeoCoordinates';
    latitude: number;
    longitude: number;
  };
};

export const SrlMapGeoPinFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'srlMapGeoPin' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SrlMapGeoPin' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'geo' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'price' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coordinates' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'coordinates' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'zoom' } },
        ],
      },
    },
    ...GeoFragmentDoc.definitions,
    ...PriceFragmentDoc.definitions,
    ...CoordinatesFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
