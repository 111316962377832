import { track } from '@hotelplan/libs.tracking-events';

export type TOfferTrackingData = {
  offer_position: number;
  sortingLabel: string;
  position: ETrackingOfferPosition;
  select_offer_page: number;
};

export enum ETrackingOfferPosition {
  ORL = 'orl',
  SRL = 'srl',
}

export const trackOfferClick = (
  language: string,
  {
    sortingLabel,
    offer_position,
    position,
    select_offer_page,
  }: TOfferTrackingData
) => {
  track({
    event: `select_offer`,
    action: `select`,
    label: `Weiter`,
    category: `Sortierung nach ${sortingLabel}`,
    component: 'offer-card',
    position,
    language,
    select_offer_page: select_offer_page.toString(),
    select_offer_position: offer_position.toString(),
    _clear: true,
  });
};
