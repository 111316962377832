import React, { useMemo } from 'react';
import {
  AbsoluteDropdown,
  DropDownWrapper,
} from '@hotelplan/components.common.modals';
import { IOption } from '@hotelplan/components.common.select-input';
import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { useToggleState } from '@hotelplan/libs.hooks-react';
import {
  ChevronArrow,
  CustomInput,
  CustomOption,
  CustomSelectInputWrapper,
} from './CustomSelectInput.styled';

interface ICustomSelectInputProps {
  value: string;
  options: Array<IOption>;
  onChange: (nextValue: string) => void;
  label?: string;
  hideLabel?: boolean;
  className?: string;
}

export default function CustomSelectInput({
  value,
  options,
  onChange,
  label,
  hideLabel,
  className,
}: ICustomSelectInputProps): React.ReactElement {
  const [showOptions, , close, toggle] = useToggleState(false);
  const inputWrapperRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside([inputWrapperRef], close);

  const valuedLabel = useMemo(
    function () {
      return options.find(option => option.value === value)?.label || '';
    },
    [value, options]
  );

  return (
    <CustomSelectInputWrapper className={className}>
      {label && !hideLabel ? <span className="label">{label}</span> : null}
      <div ref={inputWrapperRef}>
        <CustomInput className="input" onClick={toggle}>
          <span className="value">{valuedLabel}</span>
          <ChevronArrow />
        </CustomInput>
        <DropDownWrapper>
          <AbsoluteDropdown isShown={showOptions}>
            {options.map(option => {
              return (
                <CustomOption
                  key={option.value}
                  onClick={() => {
                    onChange(option.value);
                    close();
                  }}
                >
                  {option.label}
                </CustomOption>
              );
            })}
          </AbsoluteDropdown>
        </DropDownWrapper>
      </div>
    </CustomSelectInputWrapper>
  );
}
